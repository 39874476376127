import React, { lazy, Suspense } from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import { ConfigProvider, Spin } from 'antd'
import spinner from 'assets/spinner.png'
import 'spinner.style.less'
import 'App.style.less'
import { useCheckWasmAvailability } from 'Maposaic/utils'
import 'firebaseService/initialize'
import TermsAndConditions from 'TermsAndConditions/TermsAndConditions'
import { PRIMARY_COLOR } from 'constants/colors'

const Gallery = lazy(() => import('Gallery/Gallery'))
const Maposaic = lazy(() => import('Maposaic/Maposaic'))
const GameOfLife = lazy(() => import('GameOfLife/GameOfLife'))
const HomePage = lazy(() => import('HomePage/HomePage'))

const App = () => {
  const { isWasmAvailable } = useCheckWasmAvailability()

  return (
    <ConfigProvider theme={{ token: { colorPrimary: PRIMARY_COLOR, colorLink: PRIMARY_COLOR } }}>
      <Router>
        <Suspense
          fallback={
            <Spin
              spinning={true}
              className="fallback-spinner"
              indicator={<img className="spinner" src={spinner} alt="spin" />}
            />
          }
        >
          <Switch>
            <Route path="/gallery">
              <Gallery />
            </Route>
            <Route path="/game-of-life">
              <GameOfLife />
            </Route>
            <Route path="/terms-and-conditions">
              <TermsAndConditions />
            </Route>
            <Route path="/create">
              <Maposaic isWasmAvailable={isWasmAvailable} />
            </Route>
            <Route path="/">
              <HomePage />
            </Route>
          </Switch>
        </Suspense>
      </Router>
    </ConfigProvider>
  )
}

export default App
