export const CM_PER_INCH = 2.54
export const FORMAT_RATIO = 29.7 / 21

export enum SizeRef {
  size11x14 = '11x14',
  size18x24 = '18x24',
  size24x32 = '24x32',
  custom = 'custom',
}

export enum LengthUnit {
  inch = 'inch',
  cm = 'cm',
}

export const CM_PER_INCH_FACTOR = {
  [LengthUnit.cm]: 1,
  [LengthUnit.inch]: CM_PER_INCH,
}

export type Size = {
  shortestLength: number
  longestLength: number
}

export const FORMAT_SIZE = {
  [SizeRef.size11x14]: { shortestLength: 27.94, longestLength: 35.56 },
  [SizeRef.size18x24]: { shortestLength: 45.72, longestLength: 60.96 },
  [SizeRef.size24x32]: { shortestLength: 60.96, longestLength: 81.28 },
}

export const FORMAT_CHOICES = [
  { key: SizeRef.size11x14, label: { [LengthUnit.cm]: '28 x 35', [LengthUnit.inch]: '11 x 14' } },
  { key: SizeRef.size18x24, label: { [LengthUnit.cm]: '45 x 60', [LengthUnit.inch]: '18 x 24' } },
  { key: SizeRef.size24x32, label: { [LengthUnit.cm]: '60 x 80', [LengthUnit.inch]: '24 x 32' } },
  { key: SizeRef.custom, label: { [LengthUnit.cm]: 'custom...', [LengthUnit.inch]: 'custom...' } },
]
